import { ref, computed } from "vue"
import { defineStore } from "pinia"

const useChargingHomeListStore = defineStore({
	id: "charging_home_list",
	state: () => ({
		list: [],
	}),
	actions: {
		set(key, value) {
			this[key] = value
		},
	},
	persist: true,
})

export { useChargingHomeListStore }
