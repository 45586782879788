import { ref, computed } from "vue"
import { defineStore } from "pinia"

const useUserStore = defineStore({
	id: "user",
	state: () => ({
		user: null,
	}),
	actions: {
		set(key, value) {
			this[key] = value
		},
		setBalance(value) {
			this.user.balance = value
		}
	},
	persist: true,
})

export { useUserStore }
