import { ref, computed } from "vue"
import { defineStore } from "pinia"

const usePinpointStore = defineStore({
	id: "pinpoint",
	state: () => ({
		location: null,
		latitude: null,
		longitude: null,
		postal_code: null,
	}),
	actions: {
		set(key, value) {
			this[key] = value
		},
	},
	persist: true,
})

export { usePinpointStore }
