import { defineStore } from "pinia"

const useAuthStore = defineStore({
	id: "auth",
	state: () => ({
		accessToken: null,
		firebaseToken: null,
		isOTPSent: false,
		resendOTPAt: null,
		authToken: null,
		isNewUser: false,
		ip: null,
	}),
	actions: {
		set(key, value) {
			this[key] = value
		},
		refreshIp() {
			getIp()
		},
	},
	persist: true,
})

import axios from "axios"

const apiUrl = import.meta.env.VITE_IP_CHECKER_URL

const api = axios.create({
	baseURL: apiUrl,
	headers: {
		"Content-Type": "application/json",
	},
})

const getIp = async () => {
	const { data } = await api.get("/")
	useAuthStore().set("ip", data)
}

getIp()

export { useAuthStore }
