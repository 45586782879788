import "./assets/main.css"
import "./disableZoom.js"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"
import { LoadingPlugin } from "vue-loading-overlay"
import "vue-loading-overlay/dist/css/index.css"
import * as Sentry from "@sentry/vue";

import { useAuthStore } from "@/stores/auth"
import { usePinpointStore } from "@/stores/pinpoint"
import { useStationStore } from "@/stores/station"
import { useLocationStore } from "@/stores/location"
import { useChargingHomeListStore } from "@/stores/charging_home_list"
import { useUserStore } from "@/stores/user"

import { createApp } from "vue"
import { createPinia } from "pinia"

import App from "./App.vue"
import router from "./router"

const app = createApp(App)

const config = {
  environment: import.meta.env.VITE_ENV,
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserTracingIntegration({ router }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    new RegExp(`^${import.meta.env.VITE_API_URL.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}`)
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
}

Sentry.init(config)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
useAuthStore()
usePinpointStore()
useStationStore()
useLocationStore()
useChargingHomeListStore()
useUserStore()

app.use(router)
app.use(LoadingPlugin)

app.mount("#app")
