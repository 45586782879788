import { createRouter, createWebHistory } from "vue-router"
import { useAuthStore } from "@/stores/auth"

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: "/",
			name: "home",
			redirect: "/dashboard",
			component: () => import("../views/MainView.vue"),
			children: [
				{
					path: "dashboard",
					name: "dashboard",
					component: () => import("../views/DashboardView.vue"),
				},
			],
		},

		// LOCATION
		{
			path: "/balance",
			name: "balance",
			component: () => import("../views/balance/BalanceView.vue"),
			meta: { requiresLogin: true },
		},
		{
			path: "/balance/top-up",
			name: "balance-top-up",
			component: () => import("../views/balance/BalanceTopUpView.vue"),
			meta: { requiresLogin: true },
		},
		{
			path: "/balance/withdraw",
			name: "balance-withdraw",
			component: () => import("../views/balance/BalanceWithdrawView.vue"),
			meta: { requiresLogin: true },
		},
		// LOCATION

		// LOCATION
		{
			path: "/location",
			name: "location",
			component: () => import("../views/location/LocationListView.vue"),
		},
		{
			path: "/location/:id",
			name: "location-detail",
			component: () => import("../views/location/LocationDetailView.vue"),
		},
		// LOCATION

		// STATION
		{
			path: "/station",
			name: "station",
			component: () => import("../views/station/StationListView.vue"),
			meta: { requiresLogin: true },
		},
		{
			path: "/station/:station_id",
			name: "station-detail",
			component: () => import("../views/station/StationDetailView.vue"),
			meta: { requiresLogin: true },
		},
		{
			path: "/station/add",
			name: "station-add",
			component: () => import("../views/station/StationAddView.vue"),
			meta: { requiresLogin: true },
		},
		// STATION

		// HISTORY
		{
			path: "/history",
			name: "history",
			component: () => import("../views/history/HistoryListView.vue"),
		},
		{
			path: "/history/:id",
			name: "history-detail",
			component: () => import("../views/history/HistoryDetailView.vue"),
			meta: { requiresLogin: true },
		},
		// HISTORY

		// CHARGE
		{
			path: "/charge",
			name: "charge",
			component: () => import("../views/charge/ChargeScan.vue"),
			meta: { requiresLogin: true },
		},
		{
			path: "/c/:code",
			name: "charge-prepare",
			component: () => import("../views/charge/ChargePrepareView.vue"),
			meta: { requiresLogin: true },
		},
		// CHARGE

		{
			path: "/profile",
			name: "profile",
			component: () => import("../views/profile/ProfileDetailView.vue"),
			meta: { requiresLogin: true },
		},
		{
			path: "/about",
			name: "about",
			component: () => import("../views/AboutView.vue"),
			meta: { requiresLogin: true },
		},
		{
			path: "/auth",
			name: "auth",
			component: () => import("../views/AuthView.vue"),
			meta: { requiresNoLogin: true },
		},
		{
			// not found page
			path: "/:pathMatch(.*)*",
			name: "not-found",
			component: () => import("../views/NotFoundView.vue"),
		},
	],
})

router.beforeEach((to, from, next) => {
	const storeAuth = useAuthStore()

	let authenticated = false
	if (storeAuth.accessToken) {
		authenticated = true
	}

	const requiresLogin = to.matched.some((record) => record.meta.requiresLogin)
	const requiresNoLogin = to.matched.some(
		(record) => record.meta.requiresNoLogin,
	)

	if (requiresLogin && !authenticated) {
		const referrer = to.fullPath
		next("/auth?referrer=" + referrer)
	} else if (requiresNoLogin && authenticated) {
		next("/dashboard")
	} else {
		next()
	}
})

export default router
