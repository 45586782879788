import { ref, computed } from "vue"
import { defineStore } from "pinia"

const useStationStore = defineStore({
	id: "station",
	state: () => ({
		selected: null,
	}),
	actions: {
		set(key, value) {
			this[key] = value
		},
	},
	persist: true,
})

export { useStationStore }
